import {ThemeValue} from "grommet";

export const theme: ThemeValue = {
  global: {
    colors: {
      background: '#323232',
      blue1: '#2aaee9',
    }
  }
}
